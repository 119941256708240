import * as React from 'react';
import { SfConfigModel, ServiceModel } from "../../../SharedContent/ServiceClass"
import { SfCustomerFieldModel, SfCustomerFields } from "../../../SharedContent/Login/SfCustomerFieldModel"
import { SfCustomerModel } from "../../../SharedContent/Login/SfCustomerModel";
import { CustomerFormType } from "../../../SharedContent/Login/CustomerFormType";
import { SfCustomerAndFieldsModel } from "../../../SharedContent/Login/SfCustomerAndFieldsModel";
import { LoginValidationService } from "../../../SharedContent/Login/LoginValidationService";
import { ResponseModel } from "../../../SharedContent/ResponseModel";
import { ValidationMessages } from '../../../SharedContent/Login/ValidationMessages';
import { ValidationModel } from '../../../SharedContent/Login/ValidationModel';
import { FieldValidatorModel } from '../../../SharedContent/Login/FieldValidatorModel';
import { BirthdateModel } from "../../../SharedContent/Login/BirthdateModel";
import { ResponseCode } from '../../../SharedContent/ResponseCode';

export class RegisterComponent extends React.Component<any, any>{

    private static readonly GetSfCustomerFieldsViaPOST_URL = "SfCustomer/GetSfCustomerFields";

    constructor(props: any) {
        super(props);
        this.state = {
            value: null,
            isLoggedIn: false,
            renderValues: false,
            sfCustomerAndFields: {
                sfCustomer: {},
                sfCustomerFields: {}
            }
        };
        this.getRegisterContent();
    }

    private _config: SfConfigModel = new SfConfigModel();
    private sfCustomerAndFields: SfCustomerAndFieldsModel = {
        sfCustomer: {} as any,
        validationErrorList: [],
        sfCustomerFields: null,
        sfCustomerFormType: CustomerFormType.REGISTRATION,
        validations: {}
    } as any;
    private _serviceModel: ServiceModel = new ServiceModel();
    private _fieldsDataLoaded: boolean = false;
    private _fieldsData_IsLoading: boolean = false;

    handleInputChange(event) {
        var name = event.target.name;
        var value = event.target.value;
        this.sfCustomerAndFields.sfCustomer[name] = value;
        if (this.sfCustomerAndFields.sfCustomerFields[name].IS_REQUIRED
            && (value == null || value == "")) {
            if (this.sfCustomerAndFields.sfCustomerFields[name].ErrorMessages == null) {
                this.sfCustomerAndFields.sfCustomerFields[name].ErrorMessages = [];
            }
            this.sfCustomerAndFields.sfCustomerFields[name].ErrorMessages =
                this.errorMessageToString({ required: true } as any);
            this.updateSfCustomerFieldsState();
        } else if (this.removeInvalidToken(name)) this.updateSfCustomerFieldsState();
    }

    handleInputChangeObject(event, type) {
        var name = event.target.name;
        var value = event.target.value;
        if (this.sfCustomerAndFields.sfCustomer[name] == null ||
            typeof this.sfCustomerAndFields.sfCustomer[name] !== "object") {
            this.sfCustomerAndFields.sfCustomer[name] = {};
        }
        if (value === "") value = null;
        this.sfCustomerAndFields.sfCustomer[name][type] = value;
    }

    submitRegistration_CLICK = this.submitRegistration.bind(this);
    submitRegistration() {
        this.register();
    }
    check_KEYPRESS(evt: any) {
        if (evt.code == 13 || evt.keyCode == 13) {
            this.submitRegistration_CLICK();
        }
    }
    private captchaError: boolean = false;

    private register() {
        var self = this;
        if ((window as any).captchaEnabled) {
            (this.sfCustomerAndFields.sfCustomer as any).CaptchaResponse = (window as any).grecaptcha.getResponse();
            if ((this.sfCustomerAndFields.sfCustomer as any).CaptchaResponse == "") {
                this.captchaError = true;
                this.forceUpdate();
                return Promise.resolve(false);
            }
        }
        if (this.validateAndApply()) {
            return fetch(document.getElementsByTagName("base")[0].href + "Login/RegisterCustomer", {
                    method: "POST",
                    body: JSON.stringify(this.sfCustomerAndFields.sfCustomer),
                    headers: { "Content-Type": "application/json" }
                }).then(function (response) {
                    return response.json();
                }).then(function (json: ResponseModel<any>) {
                    if (json != null && json.ResponseCode == ResponseCode.CaptchaError ) {
                        self.captchaError = true;
                        return;
                    } else {
                        self.captchaError = false;
                    }
                    if (json.ResponseCode != ResponseCode.SessionAlreadyRegistered &&
                        json.ResponseObject.validationErrorList.length > 0) {
                        self.applyValidation(json.ResponseObject.validationErrorList);
                        self.updateSfCustomerFieldsState();
                        return false;
                    }
                    else {
                        (window as any).$ClientMiddleware.CheckLoginMiddleware();
                    }
                    return json.ResponseObject.sfCustomer;
                });
        } else return Promise.resolve(false);
    }

    private validateAndApply() {
        var setState: boolean = false;
        for (var field in this.sfCustomerAndFields.sfCustomerFields) {
            setState = this.removeInvalidToken(field) || setState;
        }
        var clientValidation = LoginValidationService.validate(this.sfCustomerAndFields, false);
        if (clientValidation && clientValidation.length > 0) {
            this.applyValidation(clientValidation);
            this.updateSfCustomerFieldsState();
            return false;
        }
        if (setState) this.updateSfCustomerFieldsState();
        return true;
    }

    private removeInvalidToken(field: string) {
        var errs: any = {};
        var setState = this.sfCustomerAndFields.sfCustomerFields[field].ErrorMessages != null &&
            this.sfCustomerAndFields.sfCustomerFields[field].ErrorMessages.length > 0;
        for (var i in ValidationMessages) {
            errs[i] = false;
        }
        this.sfCustomerAndFields.sfCustomerFields[field].Errors = errs;
        this.sfCustomerAndFields.sfCustomerFields[field].ErrorMessages = [];
        return setState;
    }

    private updateSfCustomerFieldsState() {
        this.setState({
            sfCustomerAndFields: {
                sfCustomerFields: this.sfCustomerAndFields.sfCustomerFields
            }
        });
    }

    //TODO: this is temporary!
    private errorMessageToString(validations: ValidationModel): (string|JSX.Element)[] {
        var messages: (string|JSX.Element)[] = [];
        if (validations.required) messages.push("Required");
        if (validations.invalid) messages.push("Invalid");
        if (validations.alreadyRegistered) messages.push(
            <span>
                This email already has an account with us. Choose a different email or&nbsp;
                <a onClick={e => this.props.cancel()}>login</a>. If you have forgotten your password,&nbsp;
                <a  onClick={e => this.props.forgotPassword()}>click here</a>.
            </span>
        );
        if (validations.postalMismatch) messages.push("Postal codes do not match.");
        if (validations.selectState) messages.push("Select a state");
        if (validations.passwordTooShort) messages.push("Password is too short.");
        if (validations.passwordTooLong) messages.push("Password is too long.");
        if (validations.not18YearsOld) messages.push("You must be 18 years of age to use this site.");
        if (validations.illegalCharacter) messages.push("An entered character is not allowed.");
        if (validations.passwordMismatch) messages.push("The passwords do not match.");
        if (validations.noPostalCode) messages.push("No postal code found");
        return messages;
    }

    private applyValidation(validation: FieldValidatorModel[]) {
        var i: number, sfCustomerFieldID: number;
        let validator: { [key: string]: { [key: string]: boolean } } = {};
        for (i = 0; i < validation.length; i += 1) {
            var field = this.sfCustomerAndFields.sfCustomerFields[validation[i].ObjectToApplyOn];
            if (validator[validation[i].ObjectToApplyOn] == null) {
                validator[validation[i].ObjectToApplyOn] = {};
            }
            validator[validation[i].ObjectToApplyOn][validation[i].ValidationMessage] = true;
            (field.Errors as any)[validation[i].ValidationMessage] = true;
            //TODO: This line is temporary and should be removed
            field.ErrorMessages = this.errorMessageToString(field.Errors);
        }
        this.sfCustomerAndFields.validations = validator;
    }

    /* This is a little misleading, as SHOW_ON_LOGIN is always false */
    customerNgIf(sfCustomerField: SfCustomerFieldModel, sfCustomerForm: SfCustomerAndFieldsModel) {
        return sfCustomerField != null &&
            (sfCustomerForm.sfCustomerFormType === CustomerFormType.REGISTRATION && sfCustomerField.SHOW_ON_REGISTRATION ||
                sfCustomerForm.sfCustomerFormType === CustomerFormType.EDIT_PROFILE && sfCustomerField.SHOW_ON_EDIT_PROFILE ||
                sfCustomerForm.sfCustomerFormType === CustomerFormType.GUEST && sfCustomerField.SHOW_FOR_GUEST ||
                sfCustomerForm.sfCustomerFormType === CustomerFormType.LOGIN &&
                (sfCustomerField.SF_CUSTOMER_FIELD_VALUE === "PASSWORD_TO_BE_SENT" || sfCustomerField.SF_CUSTOMER_FIELD_VALUE === "EMAIL") ||
                sfCustomerForm.sfCustomerFormType === CustomerFormType.EMAIL_ONLY && sfCustomerField.DATA_SOURCE_NAME === "emailtextbox"
            );
    }

    render() {
        return this.getMainContent();
    }

    getMainContent() {
        return <div>
            {(this.state.sfCustomerAndFields !== undefined &&
                this.getRegistrationForm(this.state.sfCustomerAndFields.sfCustomerFields)
            )}
        </div>
    }

    componentDidMount() {
    }

    getRegistrationForm(sfCustomerFields: any) {
        var formContent;
        if (sfCustomerFields !== undefined) {
            formContent =
                <form name="registerForm" className="register-form" >
                    {this.getFormElementsFromObjects(sfCustomerFields)}
                    {this.getFormButtons()}
                </form>
        } else {
            return <></>;
        }
        return formContent;
    }
    getFormButtons(){
        var buttons = [];
        buttons.push(this.getCaptcha());
        buttons.push(
            <button key={20} className="sign-in__button button button--green button--expand" 
                onClick={evt => this.submitRegistration_CLICK()}
                type="button">{(window as any).stringResourcesSignIn.Register}</button>); 
        buttons.push(
            <button key={21} 
                className="button button--grey button--expand"  
                onClick={() => this.props.cancel()}
                type="button">{(window as any).stringResourcesSignIn.Cancel}</button>);
        return buttons;
    }

    getFormElementsFromObjects(sfCustomerFields: any) {
        var formElements = [];
        var keyIterator = 0;
        var self = this;
        for (var propName in sfCustomerFields) {
            var sfCustomerField = sfCustomerFields[propName] as SfCustomerFieldModel;
            formElements.push(
                <div key={keyIterator++}>
                    <label>{sfCustomerField.SF_CUSTOMER_FIELD_TRANSLATION}&nbsp;
                        {sfCustomerField.IS_REQUIRED ? <span className="required-marker">*</span> : ""}
                    </label>
                    {self.getInputFieldBasedOnDataSourceType(sfCustomerField, keyIterator, propName)}
                    {self.getErrors(sfCustomerField)}
                </div>
            )
        }
        return formElements;
    }

    getErrors(sfCustomerField: SfCustomerFieldModel) {
        var els = [];
        for (var error in sfCustomerField.ErrorMessages) {
            els.push(this.getSingleError(sfCustomerField.ErrorMessages[error]))
        }
        return els;
    }

    getSingleError(error: string | JSX.Element) {
        return <div className="sfCustomer-error"> {error} </div>
    }

    getInputFieldBasedOnDataSourceType(sfCustomerField: SfCustomerFieldModel, indexId: number, propName: string) {
        if (sfCustomerField.DATA_SOURCE_NAME === 'emailtextbox') {
            return (
                <input name={propName} onChange={evt => this.handleInputChange(evt)} onKeyUp={evt => this.check_KEYPRESS(evt)}  type="email" id={'sfcustomerfield_' + indexId} className={'sfcustomer-input'}>

                </input>)
        } else if (sfCustomerField.DATA_SOURCE_NAME === 'textbox') {
            return (
                <input name={propName} onChange={evt => this.handleInputChange(evt)} onKeyUp={evt => this.check_KEYPRESS(evt)}  type="text" id={'sfcustomerfield_' + indexId} className={'sfcustomer-input'}>

                </input>)
        }
        else if (sfCustomerField.DATA_SOURCE_NAME === 'passwordbox' || sfCustomerField.DATA_SOURCE_NAME === 'confirmpasswordbox') {
            return (
                <input name={propName} onChange={evt => this.handleInputChange(evt)} onKeyUp={evt => this.check_KEYPRESS(evt)}  type="password" id={'sfcustomerfield_' + indexId} className={'sfcustomer-input'}>

                </input>)
        }
        else if (sfCustomerField.DATA_SOURCE_NAME === 'titleddl') {
            return (
                <select name={propName} onChange={evt => this.handleInputChange(evt)} onKeyUp={evt => this.check_KEYPRESS(evt)}  className={'sfcustomer-input'}>
                    <option value="">{(window as any).stringResourcesSignIn.SelectATitle}</option>
                    {this.getListOptionContent(sfCustomerField.DATA_SOURCE_OBJECT)}
                </select>)
        }
        else if (sfCustomerField.DATA_SOURCE_NAME === 'birthdatepicker') {
            return (
                <div id={'sfcustomerfield_' + indexId} className="bday-row">
                    <div className="bday-select" key={indexId + 100}>
                        <div className="select-input">
                            <select name={propName} className={'sfcustomer-input'} onChange={event => this.handleInputChangeObject(event, "day")}>
                                <option value="">{(window as any).stringResourcesSignIn.Day}</option>
                                {this.getRangeOptionContent(sfCustomerField.DATA_SOURCE_OBJECT.dayRange.startDate,
                                    sfCustomerField.DATA_SOURCE_OBJECT.dayRange.endDate)}

                            </select>

                            <span className="select-icon"><i className="fa fa-caret-up"></i><i className="fa fa-caret-down"></i></span>
                        </div>
                    </div>
                    <div className="bday-select" key={indexId + 101}>
                        <div className="select-input">
                            <select name={propName} className={'sfcustomer-input'} onChange={event => this.handleInputChangeObject(event, "month")}>
                                <option value="">{(window as any).stringResourcesSignIn.Month}</option>
                                {this.getListOptionContent(sfCustomerField.DATA_SOURCE_OBJECT.monthList, true)}

                            </select>

                            <span className="select-icon"><i className="fa fa-caret-up"></i><i className="fa fa-caret-down"></i></span>
                        </div>
                    </div>
                    <div className="bday-select" key={indexId + 102}>
                        <div className="select-input">
                            <select name={propName} className={'sfcustomer-input'} onChange={event => this.handleInputChangeObject(event, "year")}>
                                <option value="">{(window as any).stringResourcesSignIn.Year}</option>
                                {this.getRangeOptionContent(sfCustomerField.DATA_SOURCE_OBJECT.yearRange.startDate,
                                    sfCustomerField.DATA_SOURCE_OBJECT.yearRange.endDate)}

                            </select>

                            <span className="select-icon"><i className="fa fa-caret-up"></i><i className="fa fa-caret-down"></i></span>
                        </div>
                    </div>
                </div>
            )
        }
        else if (sfCustomerField.DATA_SOURCE_NAME === 'gendercheckbox') {
            return (
                <div className="regester-gender display-flex">
                    <div className="select-gender">
                        <input id="radio-register-male" type="radio" value="M" name={propName} onChange={evt => this.handleInputChange(evt)} onKeyUp={evt => this.check_KEYPRESS(evt)} />
                        <label className="display-flex" htmlFor="radio-register-male">
                            <span className="radio-button flex-vertical-align-self"></span>
                            <span className="flex-vertical-align-self">{(window as any).stringResourcesSignIn.Male}</span>
                        </label>
                    </div>
                    <div className="select-gender">
                        <input id="radio-register-female" type="radio" value="F" name={propName} onChange={evt => this.handleInputChange(evt)} onKeyUp={evt => this.check_KEYPRESS(evt)} />
                        <label className="display-flex" htmlFor="radio-register-female">
                            <span className="radio-button flex-vertical-align-self"></span>
                            <span className="flex-vertical-align-self">{(window as any).stringResourcesSignIn.Female}</span>
                        </label>
                    </div>
                </div>
                );
        }
        return <></>;
    }

    getRangeOptionContent(from:number, to:number){
        var optionsArray = [];
        for(var i=from; i<=to; i++){
            optionsArray.push(<option value={i}>{i}</option>)
        }
        return optionsArray;
    }

    getListOptionContent(list: any, useKey?: boolean) {
        var optionsArray = [];
        for (var value in list) {
            if (useKey) {
                optionsArray.push(<option value={value}>{list[value]}</option>);
            } else {
                optionsArray.push(<option value={list[value]}>{list[value]}</option>)
            }
        }
        return optionsArray;
    }

    getRegisterContent() {
        if (this.sfCustomerAndFields.sfCustomerFields == null && !this._fieldsData_IsLoading && !this._fieldsDataLoaded) {
            this._fieldsData_IsLoading = true;
            this._serviceModel.postDataPromise(undefined, this._config.GetBaseUrl()
                + RegisterComponent.GetSfCustomerFieldsViaPOST_URL)
                .then(response => {
                    this._fieldsData_IsLoading = false;
                    if (response !== undefined && response.status === 200) {
                        response.json()
                            .then((result) => {
                                if (result !== undefined && result.ResponseObject !== undefined)
                                this.sfCustomerAndFields.sfCustomerFields = result.ResponseObject;
                                //var objectKeys = Object.keys(result.ResponseObject);
                                this.setState({
                                    sfCustomerAndFields: {
                                        sfCustomerFields: result.ResponseObject
                                    }
                                });
                                this._fieldsDataLoaded = true;
                            });
                    }
                }).catch((error) => {
                    this._fieldsData_IsLoading = false;
                    console.error(error);
                });
        }
    }

    loadCaptcha() {
        let siteKey: string;
        if (!(window as any).captchaEnabled) return;
        if (window.location.href.indexOf("biovea.website.45") > -1) {
            siteKey = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
        } else {
            siteKey = "6LdxN1EcAAAAAPhz-fztJ1CCMucCDFzsJblrkUyl";
        }
        var registerCaptcha = document.getElementById("register_captcha");
        if (registerCaptcha == null && registerCaptcha.children.length > 0) return;
        try {
            (window as any).grecaptcha.render('register_captcha', {
                'sitekey': siteKey,
                'callback': (response) => {
                    this.captchaError = false;
                    this.forceUpdate();
                }
            });
        } catch (e) { }
        if (this.captchaCssHackIntervalId == null) {
            this.captchaCssHackIntervalId = setInterval(() => this.captchaCssHack(), 1000);
        }
    }

    captchaCssHackIntervalId: number | null;
    captchaCssHack() {
        let x = document.querySelectorAll(".g-recaptcha-bubble-arrow ~ *, .g-recaptcha-bubble-arrow");
        //if (x.length < 3) {
        //    return;
        //}
        for (let y of (x as any)) { y.style.position = "fixed"; y.style.bottom = "0"; y.style.right = "0"; }
        //setTimeout(this.captchaCssHack, 1000);

    }

    getCaptcha() {
        setTimeout(() => {
            if ((window as any).captchaEnabled) {
                this.loadCaptcha();
            }
        }, 1000);
        return <>
            <div id="register_captcha"></div>
            {
                this.captchaError && (window as any).captchaEnabled &&
                <div className="sfCustomer-error"> <br />Captcha {(window as any).stringResourcesSignIn.Required} </div>
            }
        </>
    }
}