import * as React from 'react';
import { SfCustomerModel } from '../../../SharedContent/SfCustomerModel'
import { Observer } from '../../../SharedContent/Observer'
import { SignInModel, SignInProperty, ForgotPassModel } from '../../../SharedContent/Login/SignInModel';
import { RegisterComponent } from "./RegisterComponent";

declare global {
    interface Window {
        $SfCustomerSubject: any;
        $ClientMiddleware: any;
    }
}

export class LoginMainComponent extends React.Component<any, any>{
    _signInModel: SignInModel;
    _forgotPassModel: ForgotPassModel;
    private static readonly LoginURL = "Login/SignIn";
    private static readonly ForgotPasswordURL = "Login/ForgotPasswordApi";
    private observer: any;
    constructor(props: any) {
        super(props);
        this._signInModel = new SignInModel();
        this._forgotPassModel = new ForgotPassModel();
        this.state = {
            value: null,
            isLoggedIn: false,
            renderValues: false,
            stateObserver: undefined,
            signInModel: this._signInModel,
            forgotPassModel: this._forgotPassModel
        };
        this.submitLogin_CLICK = this.submitLogin_CLICK.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateInputValue = this.updateInputValue.bind(this);
        this.revealForgotPassword = this.revealForgotPassword.bind(this);
        this.updateForgotPassInputValue = this.updateForgotPassInputValue.bind(this);
        this.submitForgotPassword_CLICK = this.submitForgotPassword_CLICK.bind(this);
        this.revealRegistration_CLICK = this.revealRegistration_CLICK.bind(this);
        this.cancel = this.cancel.bind(this);
        this.close = this.close.bind(this);
        this.observer = {
            id: "login" + Math.trunc(Math.random() * 1000000),
            update: (result) => {
                if (result.LoggedIn) this.close();
            }
        };
        window.$SfCustomerSubject.addObserver(this.observer);
        //this.forgotPasswordCheck();
    }

    componentDidMount() {
        this.initObserverAndSubscribeToSfCustomerSubject();
        this.forgotPasswordCheck();
    }

    componentWillUnmount() {
        window.$SfCustomerSubject.removeObserver(this.observer);
    }
    check_KEYUP(evt: any) {
        if (evt.code == 13 || evt.keyCode == 13) {
            this.submitLogin_CLICK(evt);
        }

    }


    submitLogin_CLICK(evt: any) {
        var hasValidationErrors = false;
        Object.keys(this._signInModel).forEach(key => {
            if(this._signInModel[key] instanceof SignInProperty){
                let signInProp = this._signInModel[key] as SignInProperty;
                if (signInProp.IsRequired && signInProp.value === undefined || signInProp.value === "") {
                    signInProp.HasError = true;
                    hasValidationErrors = true;
                } else if (signInProp.HasError) {
                    signInProp.HasError = false;
                }
            }
        });
        this.setState({
            signInModel: this._signInModel
        });
        var cresponse = "";
        if ((window as any).captchaEnabled) {
            cresponse = (window as any).grecaptcha.getResponse();
            if (cresponse == "") {
                hasValidationErrors = true;
                this.captchaError = true;
            }
        }

        if (!hasValidationErrors) {
            var sendModel = {
                Username: this._signInModel.EMAIL.value,
                Password: this._signInModel.PASSWORD.value,
                KeepMeSignedIn: this._signInModel.KeepMeSignedIn.value,
                CaptchaResponse: cresponse
            };
            this.loginPostDataPromise(sendModel, this.GetBaseUrl() + LoginMainComponent.LoginURL)
                .then(result => {
                    if (result != null && result.ResponseCode == 12 /*captchaError */) {
                        this.captchaError = true;
                        return;
                    } else {
                        this.captchaError = false;
                    }
                    if ((window as any).captchaEnabled) {
                        (window as any).grecaptcha.reset();
                    }
                    if (result !== undefined) {
                        this._signInModel.loginError = false;
                        if (result.LoggedIn) {
                            this.setState({
                                revealLogin: false
                            });
                            window.$ClientMiddleware.CheckLoginMiddleware();

                        } else {
                            this.signInError();
                        }
                    } else {
                        this.signInError();
                    }
                }).catch(error => {
                    if ((window as any).captchaEnabled) {
                        (window as any).grecaptcha.reset();
                    }
                    this.signInError();
                })
        }
        return false;
    }

    revealForgotPassword() {
        this._forgotPassModel = new ForgotPassModel();
        if (this._signInModel.EMAIL !== undefined && this._signInModel.EMAIL.value !== undefined) {
            this._forgotPassModel.EMAIL.value = this._signInModel.EMAIL.value;
        }
        this.setState({
            revealRegistration: false,
            revealForgotPass: true,
            forgotPassModel: this._forgotPassModel
        });
    }

    cancel() {
        this.setState({
            revealForgotPass: false,
            revealRegistration: false,
            _signInModel: new SignInModel()
        });
    }

    close() {
        console.log(this.props.close);
        this.cancel();
        this.props.close();
    }

    revealRegistration_CLICK() {
        this.setState({
            revealForgotPass: false,
            revealRegistration: true
        });
    }

    submitForgotPassword_CLICK(evt: any) {
        var hasValidationErrors = false;
        Object.keys(this._forgotPassModel).forEach(key => {
            if(this._forgotPassModel[key] instanceof SignInProperty){
                let signInProp = this._forgotPassModel[key] as SignInProperty;
                if (signInProp.IsRequired && signInProp.value === undefined || signInProp.value === "") {
                    signInProp.HasError = true;
                    hasValidationErrors = true;
                } else {
                    if (signInProp.HasError) {
                        signInProp.HasError = false;
                    }
                }
            }
        });
        this.setState({
            forgotPassModel: this._forgotPassModel
        });
        if(!hasValidationErrors){
            var sendModel = {
                email: this._forgotPassModel.EMAIL.value,
            };
            this.postDataPromise(sendModel, this.GetBaseUrl() + LoginMainComponent.ForgotPasswordURL)
            .then(response =>{

                response.json()
                .then((result) => {
                    //console.log(JSON.stringify(result));
                });
                if(response !== undefined && response.status=== 200){
                    this._forgotPassModel.loginError = false;
                    this._forgotPassModel.requestSubmitted = true;
                    this._forgotPassModel.responseMessage = (window as any).stringResourcesSignIn.PasswordSent;
                }else{
                    this._forgotPassModel.loginError = true;
                    this._forgotPassModel.requestSubmitted = false;
                    this._forgotPassModel.responseMessage = undefined;
                }
                this.setState({forgotPassModel: this._forgotPassModel})
            }).catch(error =>{
                this._forgotPassModel.loginError = true;
                this._forgotPassModel.requestSubmitted = false;
                this._forgotPassModel.responseMessage = undefined;
                this.setState({forgotPassModel: this._forgotPassModel})
            })
        }
        return false;
    }

    signInError() {
        this._signInModel.loginError = true;
        this.setState({
            signInModel: this._signInModel
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this._signInModel[name] = value;
        this.setState({
            signInModel: this._signInModel
        });
    }

    updateInputValue(evt) {
        var name = evt.target.name;
        var value = evt.target.value;
        this._signInModel[name].value = value;
        var signInProp = this._signInModel[name] as SignInProperty;
        if (signInProp.IsRequired && (signInProp.value === undefined || signInProp.value === "")) {
            signInProp.HasError = true;
        } else {
            if (signInProp.HasError) {
                signInProp.HasError = false;
            }
        }
        this.setState({
            signInModel: this._signInModel
        });
    }

    updateForgotPassInputValue(evt) {
        var name = evt.target.name;
        var value = evt.target.value;
        this._forgotPassModel[name].value = value;
        var signInProp = this._forgotPassModel[name] as SignInProperty;
        if (signInProp.IsRequired && (signInProp.value === undefined || signInProp.value === "")) {
            signInProp.HasError = true;
        } else {
            if (signInProp.HasError) {
                signInProp.HasError = false;
            }
        }
        this.setState({
            forgotPassModel: this._forgotPassModel
        });
    }

    localObserver: Observer;
    initObserverAndSubscribeToSfCustomerSubject() {
        this.localObserver = new Observer();
        this.localObserver.id = 17;
        this.localObserver.name = "LoginComponentReact"
        this.localObserver.update = (suppliedContext) => this.receiveNotification_SfCustomer_LoginReact(suppliedContext)
        this.setState({
            stateObserver: this.localObserver
        });
        try {
            setTimeout(function (refObserver) {
                if ((window as any).$SfCustomerSubject !== undefined) {
                    var customSubj = (window as any).$SfCustomerSubject
                    customSubj.addObserver(refObserver);
                }
            }, 50, this.localObserver);
        } catch (exception) {
            console.log(exception);
        }
    }

    receiveNotification_SfCustomer_LoginReact(context: SfCustomerModel | any) {
        var self = this;
        setTimeout(function () {
            if (context !== undefined && context.LoggedIn && context.CustomerID !== undefined && context.CustomerID > 0) {
                if (self.state.sfCustomer == null ||
                    (!self.state.sfCustomer.LoggedIn || self.state.sfCustomer.CustomerID !== context.CustomerID)) {
                    self.setState({
                        isLoggedIn: true,
                        sfCustomer: context
                    });
                }
            } else {
                self.setState({
                    isLoggedIn: false,
                    sfCustomer: context
                });
            }
        }, 100);
    }

    loginPostDataPromise(data: any, url:string) {
        return fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "same-origin", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json", // "Content-Type": "application/x-www-form-urlencoded",
            },
            body: JSON.stringify(data),
        }).then((response) => response.json())
            .then((result) => {
                return result;
            }).catch((error) => {
                console.error(error);
                return error;
            });
    }

    postDataPromise(data: any, url:string) {
        return fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "same-origin", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json", // "Content-Type": "application/x-www-form-urlencoded",
            },
            body: JSON.stringify(data),
        }).then((response) => {
            return response;
        }).catch((error) => {
            console.error(error);
            return error;
        });
    }

    //Component UI rendering related

    render() {
        return this.getSignInContent();
    }

    getSignInContent() {
        var greeting;
        if (this.state.revealForgotPass) greeting = (window as any).stringResourcesSignIn.ForgotPasswordHeading;
        else if (this.state.revealRegistration) greeting = (window as any).stringResourcesSignIn.Register;
        else greeting = (window as any).stringResourcesSignIn.SignIn;
        return <div className="sign-in">
            <div className="sign-in__header">
                {this.props.hasCloseButton && <a className="sign-in__close" onClick={() => this.close()}>&times;</a>}
                <h1 className={'sign-in__heading'} >{greeting}</h1>
            </div>
            {this.getSignInForm()}
        </div>
    }

    getOKButtonContent(){
        return <div>
            {this.state.forgotPassModel.responseMessage}
            {/*<button className="button button--grey button--expand" onClick={() => this.close()} type="button" >OK</button>*/}<br /><br />
            {this.forgotPassRedirect()}
            </div>
    }

    getForgotPasswordFieldContent() {
        var loginFields = [];
        loginFields.push(
            <div>
                <div >
                    <label className="sign-in__label"> {(window as any).stringResourcesSignIn.Email}
                    </label>
                    <input type="text"
                        name="EMAIL"
                        id="txtFogotPassEmail"
                        value={this.state.forgotPassModel.EMAIL.value !== undefined ? this.state.forgotPassModel.EMAIL.value : ''}
                        onChange={evt => this.updateForgotPassInputValue(evt)}
                        className={this.state.forgotPassModel["EMAIL"].HasError ? 'sfcustomer-input has-error' : 'sfcustomer-input'}>
                    </input>

                    {this.state.forgotPassModel["EMAIL"].HasError &&
                        <div className="sfCustomer-error"> {(window as any).stringResourcesSignIn.RequiredField} </div>
                    }
                </div>
            </div>
        );
        loginFields.push(
            <div>
                <div className="sign-in__button-group">
                    <button className="sign-in__button button button--green button--expand"
                        type="button"
                        onClick={evt => this.submitForgotPassword_CLICK(evt)} >
                        {(window as any).stringResourcesSignIn.Submit}
                    </button>
                    <button className="button button--grey button--expand"
                        onClick={() => this.cancel()}
                        type="button">
                        {(window as any).stringResourcesSignIn.Cancel}
                    </button>
                </div>
            </div>
        );
        return loginFields;
    }

    getSignInForm() {
        var contenForm = [];
        if (this.state.revealRegistration) {
            return <RegisterComponent
                cancel={() => this.cancel()}
                forgotPassword={() => this.revealForgotPassword()}>
                </ RegisterComponent>
        } if (this.state.revealForgotPass) {
            if (this.state.forgotPassModel.requestSubmitted) {
                contenForm.push(this.getOKButtonContent());
            } else {
                contenForm.push(this.getForgotPasswordFieldContent());
            }
        } else {
            contenForm.push(this.getLoginFieldContent());
            contenForm.push(this.getForgotPasswordButonContent());
            contenForm.push(this.getSignInCreateAccountField())
        }
        return <form className="login-form" name="loginForm" >
            {(this.state.signInModel.loginError &&
                <p className="sign-in__callout sign-in__callout--with-icon" >
                    <span><img src="webcontent/icons/baseline-error-24px--cc4b37.svg" alt="" /></span>
                    <span>
                        {(window as any).$StringResource__ValidationMessages_txtPasswordDontMatchUser}
                    </span>
                </p>
            )}
            {contenForm}
        </form>
    }

    getLoginFieldContent() {
        var loginFields = [];
        loginFields.push(
            <div>
                <div>
                    <label className="sign-in__label"> {(window as any).stringResourcesSignIn.Email}
                    </label>
                    <input type="text"
                        name="EMAIL"
                        id="txtLoginEmail"
                        onChange={evt => this.updateInputValue(evt)}
                        onKeyUp={evt => this.check_KEYUP(evt)}
                        className={this.state.signInModel["EMAIL"].HasError ? 'sfcustomer-input has-error' : 'sfcustomer-input'}>
                    </input>
                    {this.state.signInModel["EMAIL"].HasError &&
                        <div className="sfCustomer-error"> {(window as any).stringResourcesSignIn.RequiredField} </div>
                    }
                </div>
            </div>
        );
        loginFields.push(
            <div >
                <div >
                    <label className="sign-in__label">
                        {(window as any).stringResourcesSignIn.Password}
                    </label>
                    <input type="password"
                        name="PASSWORD"
                        onChange={evt => this.updateInputValue(evt)}
                        onKeyUp={evt => this.check_KEYUP(evt)}
                        className={this.state.signInModel["PASSWORD"].HasError ? 'sfcustomer-input has-error' : 'sfcustomer-input'}>
                    </input>
                    {this.state.signInModel["PASSWORD"].HasError &&
                        <div className="sfCustomer-error"> {(window as any).stringResourcesSignIn.RequiredField} </div>
                    }

                </div>
            </div>
        );
        loginFields.push(this.getCaptcha());
        loginFields.push(
            <div className="sign-in__button-group">
                <div className="faux-checkbox">
                    <input type="checkbox"
                        id="keepMeSignedIn"
                        checked={this.state.signInModel["KeepMeSignedIn"].value}
                        onChange={this.handleInputChange}
                        name="KeepMeSignedIn">
                    </input>
                    <label htmlFor="keepMeSignedIn">
                        <span className="faux-checkbox__button"></span>
                        <span className="faux-checkbox__label">{(window as any).stringResourcesSignIn.KeepMe}</span>
                    </label>
                </div>
                <input className="sign-in__button button button--green button--expand"
                    type="button"
                    value={(window as any).stringResourcesSignIn.LogIn}
                    onClick={evt => this.submitLogin_CLICK(evt)} onKeyUp={evt => this.check_KEYUP(evt)} ></input>
            </div>
        );
        return loginFields;
    }

    loadCaptcha() {
        let siteKey: string;
        if (!(window as any).captchaEnabled) return;
        if (window.location.href.indexOf("biovea.website.45") > -1) {
            siteKey = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
        } else {
            siteKey = "6LdxN1EcAAAAAPhz-fztJ1CCMucCDFzsJblrkUyl";
        }
        var logCaptcha = document.getElementById("login_captcha");
        if (logCaptcha == null || logCaptcha.children.length > 0) return;

        try {
            (window as any).grecaptcha.render('login_captcha', {
                'sitekey': siteKey,
                'callback': () => {
                    this.captchaError = false;
                    this.forceUpdate();
                }
            });
        } catch (e){ }
    }

    private captchaError = false;

    getCaptcha() {
        setTimeout(() => {
            this.loadCaptcha();
        }, 1000);
        return <>
            <div id="login_captcha" ></div>
            {
                this.captchaError &&
                <div className="sfCustomer-error"> <br />Captcha {(window as any).stringResourcesSignIn.Required} </div>
            }
        </>
    }

    getForgotPasswordSubmitButton(){
        return <div className="sign-in__forgot-password">
            <a href="javascript:void(0)"
                onClick={evt => this.submitForgotPassword_CLICK(evt)}
                id="A1" target="_self">
                {(window as any).stringResourcesSignIn.Submit}
            </a>
        </div>
    }

    getForgotPasswordButonContent() {
        return <div className="sign-in__forgot-password">
            <a href="javascript:void(0)"
                onClick={() => this.revealForgotPassword()}
                id="A1" target="_self">
                {(window as any).stringResourcesSignIn.ForgotYourPasswordQuestion}
            </a>
        </div>
    }

    getSignInCreateAccountField() {
        return <div className="sign-in__create-account">
            <div className="sign-in__account-divider">
                <span>{(window as any).stringResourcesSignIn.Or}</span>
            </div>
            <a className="button button--grey button--expand"
            onClick={() => this.revealRegistration_CLICK()}>
                {(window as any).stringResourcesSignIn.CreateAnAccount}
        </a>
        </div>
    }

    private _baseURL = null;
    private GetBaseUrl() {
        if (this._baseURL == null) this._baseURL = document.getElementsByTagName('base')[0].href;
        return this._baseURL;
    }

    forgotPasswordCheck() {
        if (window.location.href.indexOf("/m/forgotpassword") > -1) {
            this.setState({ revealForgotPass: true });

        }
        else if (window.location.href.indexOf("fp") > -1) {
            this.setState({ revealForgotPass: true });
            //this.showForgotPass();
        }
    }

    forgotPassRedirect() {
        if (window.location.href.indexOf("CheckoutSignIn") > -1) {
            //User came from CheckoutSign in:
            return <button className="button button--grey button--expand" onClick={() => location.href = 'login/CheckoutSignInPage'} type="button">OK</button>
        } else if (window.location.href.indexOf("m/login") > -1) {
            //User came from or is on My Account:
            return <button className="button button--grey button--expand" onClick={() => location.href = 'm/login?returnUrl=m/profile'} type="button">OK</button>
        } else {
            return <button className="button button--grey button--expand" onClick={() => this.close()} type="button">OK</button>
        }
    }

}